<template>
    <MobileGameCard     v-if="$isMobile()"
                        :game="game"
                        :game_tags="game_tag_str"
                        :game_name="truncated_name"
                        :game_image="game_image"
                        :isFromSpotify="isFromSpotify"
                        :playerCount="playerCount"  /> 
    <DesktopGameCard    v-else 
                        :game="game" 
                        :game_tags="game_tag_str"
                        :game_name="truncated_name"
                        :game_image="game_image"
                        :isFromSpotify="isFromSpotify"
                        :playerCount="playerCount"
                        :show_countdown="show_countdown"
                        @optionSelected="optionSelected"
                        :creator="creator" 
                        :isDraft="false" 
                        :isAdmin="false"  />
</template>
<script>
import DesktopGameCard from '@/components/GameBrowser/GameCard/Desktop.vue'
import MobileGameCard from '@/components/GameBrowser/GameCard/Mobile.vue'
import { mapGetters } from "vuex";
function truncate(str, n) { return (str?.length > n) ? str.substr(0, n-1) + '...' : str }

//import Vue from "vue";
export default {
    name: "GameCard",
    props: ["game"],
    data() {
        return {
            creator: {}
        }
    },
    components: { DesktopGameCard, MobileGameCard },
    computed: {
        truncated_name() { return truncate(this.game.name, 26)}, 
        show_countdown() { return false },//!this.gameOver && this.game.expire_date && this.gameHasStarted && this.minutesToGameOver(this.game) <= 60},
        game_image() { return this.getImageFromGame() },
        isFromSpotify() { return this.game?.id[0] != "-" },
        game_tags() { return this.game.tags ? Object.keys(this.game.tags).slice(0,2) : [] },
        game_tag_str() { return `${this.game.tags} • by Spotify ` },
        ...mapGetters({
            users_ref: "firebase/users_ref"
      }),
    },
    mounted() {
      this.$bind("creator", this.users_ref.child(`${this.game.created_by}`))
    },
    methods: {
        optionSelected(str) {
            console.log(`GameCard.${str}() called`)
            this[str]()
        },
        getImageFromGame() {
            console.log("IS FROM SPOITFY", this.game.id[0])
            if (this.isFromSpotify) { return this.game.images ? this.game.images[0].url : "" } 
            else { return this.game?.image || "" }
        },
        playerCount() { return this.game.players ? Object.keys(this.game.players).length : 0 },

    }

}
</script>