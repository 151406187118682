<template>
  <div class="container" style="display:flex">
    <div class="body">
          <div class="container__header">
            <img src="@/assets/simonCowell.png" alt="bracket" />
          </div>
          <div class="container__body">
            <h1>You're the judge.</h1>
            <p class="sub">You decide which song makes it to the next round. </p>
            <div class="stats">
              <p>Each song clip is <span class="emphasis">30 seconds</span>.</p>
              <p>There are <span class="emphasis">8 songs</span></p>
              <p>You've staked <span class="emphasis underline">5000 tokens</span></p>
            </div>
            <div class="description">
              <p>Listen to each song, <span class="emphasis">make each vote count</span></p>
            </div>
          </div>             
    </div>
    <div class="container__footer">
        <slot name="footer"/>
    </div>
  </div>

</template>

<script>
export default {
    name: 'SimonCowellModal',
    props: ["game"],
    data () {
        return {
        }
    },
    methods: {
    },
    computed: {
      stats() {
        return {}
      }
    },
    mounted () {
    },
    created () {
    },
    destroyed () {
    },
}
</script>
<style lang="scss" scoped>

</style>



<style lang="scss" scoped>

  .container {
    background-color: #1f1f1f;
    color: #fff;
   // position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  


  &__header {
    // padding: 10px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 8.8%;

  }
  &__body {
    // padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: white;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
  
  }
.body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-left: 8vw;
  padding-right: 8vw;

}


h1 {
  color: white;
  font-family: 'Futura-Black';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;

}
.sub {
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 20px;
color: white;
}

.timer {
  display: flex;
  justify-content: space-between;

  &__label {
    font-family: 'Futura-Condensed';
    font-weight: 500;
    font-size: 20px;
  }
}
.stats {
  display: flex;
  flex-direction: column;
  gap: 1.5%;


  margin-top: 3.1%;
  margin-bottom: 10%;
}
.stats > p {
  color: white;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 14px;
}
.description {
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

}
.emphasis {
  font-weight: 600;
}
.underline {
  text-decoration: underline;
}
</style>