<template>
  <transition name="fade">
    <div class="modal" >
      <div class="modal__backdrop" @click="closeModal()"/>

      <div class="modal__dialog" style="display:flex">
        <div class="body">
              <div class="modal__header">
                  <h1>Please Rotate Your Phone </h1>
              </div>
              <div class="modal__body">
                <video src="@/assets/rotatePhonePreview.mp4" playsinline autoplay loop muted style="pointer-events: none;"></video>
                  <!--video of rotatePhonePreview.mp4 -->
                  <!-- <video autoplay loop muted playsinline>
                      <source src="https://cdn.jsdelivr.net/gh/jamesmcc/rotatePhonePreview@master/rotatePhonePreview.mp4" type="video/mp4"> -->
              </div>
        </div>
          <!-- <div class="three">
            <div class="modal__footer">
                <slot name="footer"/>
            </div>
        </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
    name: 'RotateMobileModal',
    props: [],
    data () {
        return {
        }
    },
    methods: {
    },
    computed: {
    },
    mounted () {
    },
    created () {
    },
    destroyed () {
    },
}
</script>
<style lang="scss" scoped>

</style>



<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  color: #fff;
  &__backdrop {
    background-color: rgba(0, 0, 0, .1);
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  &__dialog {
    background-color: #000;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 70%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  &__close {
    width: 30px;
    height: 30px;
  }
  &__header {
    // padding: 10px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: Rubik;
    font-weight: 700;
    font-size: 36px;
  }
  &__body {
    // padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    text-align: left;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
.body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  
}

.icon {
  height: 72px;
  width: 72px;

}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal {
  display: block;
}

h1 {
  color: white;
}
video::-webkit-media-controls-panel {
display: none !important;
opacity: 1 !important;}
</style>