<template>
  <div class="container" style="display:flex">
    <div class="body">
          <div class="container__header">
            <img src="@/assets/bracket.png" alt="bracket" />
          </div>
          <div class="container__body">
            <h1 class="large-text">This is a lil tourney</h1>
            <div class="stats">
              <div v-for="stat in stats" :key="stat.label" class="stat med-text">
                <p class="stats__label">{{stat.label}}</p>
                <p class="stats__val">{{stat.value}}</p>
              </div>
            </div>
            <div class="timer med-text">
              <p class="timer__label">Winners Announcement:</p>
              <p> ----</p>
            </div>
            <div class="description">
              <p>We’re ranking music, Earn crypto when you vote for any of the top <span class="emphasis">3</span> songs.</p>
            </div>
          </div>
             
    </div>
    <div class="container__footer">
        <slot name="footer"/>
    </div>
  </div>

</template>

<script>
export default {
    name: 'TourneyModal',
    props: ["game"],
    data () {
        return {
        }
    },
    methods: {
    },
    computed: {
      stats() {
        return [
          {
            label: 'VOTES',
            value: 64
          },
          {
            label: 'VOTERS',
            value: 8
          },
          {
            label: 'JACKPOT',
            value: "30K"
          },
        ]
      }
    },
    mounted () {
    },
    created () {
    },
    destroyed () {
    },
}
</script>
<style lang="scss" scoped>

</style>



<style lang="scss" scoped>

  .container {
    background-color: #1f1f1f;
    color: #fff;
  // position: fixed;//absolute;
    bottom: 0;
    right: 0;
    left: 0;
//    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  


  &__header {
    // padding: 10px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 8.8%;//8.8vh;

  }
  &__body {
    // padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
  
  }
.body {
  display: flex;
  flex-direction: column;
  //margin-top: 30px;
  padding-left: 8vw;
  padding-right: 8vw;
}


h1 {
  color: white;
  font-family: 'Futura-Black';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  text-align: center;
}

.timer {
  display: flex;
  justify-content: space-between;

  &__label {
    font-family: 'Futura-Condensed';
    font-weight: 500;
    font-size: 16px;
  }
}
.stats {
  display: flex;
  justify-content: space-between;
  margin-top: 3.3vh;
  margin-bottom: 3.3vh;
  &__label {
    font-family: 'Futura-Condensed';
    font-weight: 500;
    font-size: 16px;
  }
  &__val {
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 16px;
  }
  &__val:last-of-type {
    text-align: right;;
  }
}
.description {
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  margin-top: 3.9vh;
}
.emphasis {
  text-decoration: underline;
}

.large-text {
  font-size: 26px;
}
.med-text {
  font-size: 16px;
}
@media only screen and (min-width: 400px) {
  .large-text {
    font-size: 31px;
  }
  .med-text {
    font-size: 20px;
  }
}

</style>