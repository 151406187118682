// create template
const state = {
    streaming_modal: {
        show: false
    },
    genre_modal: {
        show: false
    },
}

const getters = {
    streamingModalVisible: (state) => {
        return state.streaming_modal.show
    },
    genreModalVisible: (state) => {
        return state.genre_modal.show
    }

}

const actions = {
    SHOW_STREAMING_MODAL(context, payload) {
        console.log('SHOW_STREAMING_MODAL')
        context.state.streaming_modal.show = true
    },
    HIDE_STREAMING_MODAL(context, payload) {
        context.state.streaming_modal.show = false
    },
    SHOW_GENRE_MODAL(context, payload) {
        console.log('SHOW_GENRE_MODAL')
        context.state.genre_modal.show = true
    },
    HIDE_GENRE_MODAL(context, payload) {
        context.state.genre_modal.show = false
    }
    

}

const mutations = {


}

export default {
    namespaced: true,
    state,
    getters,
    actions,   
    mutations
}