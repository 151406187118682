<template>
    <div class="portrait" >
        <div class="portrait__first" @click="closeClicked()"> <close-icon /> </div>
        <p class="subtitle">snipit.fm</p>
        <h1 class="title">{{'Welcome'.toUpperCase()}}</h1>
        <div class="tokens">
            <p class="tokens__label">Your Tokens</p>
            <token-balance />

        </div>
        <!-- <bracket-feed></bracket-feed> -->
        <div class="invite-btn" @click="nextGame()">
            <img src="@/assets/appleMessages_icon.png" alt="">
            <p>Invite a friend</p>
        </div> 
        <div class="categories-container">
            <genre-card v-for="category in categories" :key="category.id" :genre="category" class="category-card" @selectCategory="selectCategory" />
        </div>
        <!-- <VoteResults class="card2" :tracks="tracks" :game_expired="gameIsOver(game)" @ctaClicked="seeRecommended()" /> -->
    </div>
    

</template>

<script>

import { gameLogic } from "@/mixins";
import VoteResults from '@/components/GameComplete/VoteResults'
import Leaderboard from '@/components/GameComplete/Leaderboard'
import JackpotSizeLabel from '@/components/GameComplete/JackpotInfo/JackpotSizeLabel.vue';
import CloseIcon from '@/components/SVGs/Close.vue'
import BracketFeed from '@/components/Modals/Playlists/BracketFeed.vue'
import TokenBalance from '@/components/TokenBalance'
import GenreCard from '@/components/HomeMobile/GenreCard.vue'
import { db } from '@/store/modules/firebase'
export default {
  name: "HomeMobile",
  props: ["game"],
  mixins: [gameLogic],
  data() {
    return {
        categories_obj: null,
        tracks: [],
        isLoading: false,
    };
  },
  components: {
     CloseIcon, 
     TokenBalance, 
     GenreCard,
      //  VoteResults,
  },
  firebase: {
    categories_obj: db.ref('categories')
  },
  computed: {
    game_size() { return this.game ? this.game.tokens : "" },
    // return game image
    game_image() { return this.game?.image },
    categories() {  return  this.categories_obj ? Object.values(this.categories_obj).filter(category => !category.hidden) : [] },

  },
  methods: {
    nextGame() {
        this.$emit('nextGame')
    },
    closeClicked() {
        this.$emit('closeClicked')
    },
    async selectCategory(category) {
        if (this.isLoading) return
        this.isLoading = true
        var game = await this.$store.dispatch('gameCreation/createGameFromCategory', {category: category})
        this.$store.dispatch('context/joinGame', {game: game})
        this.isLoading = false
    },

  }
};


</script>


<style lang="scss" scoped>
.tokens {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    width: 75%;
    margin-top: 16px;

    max-width: 300px;
    font-family: 'Rubik-Bold';
    &__label {
        font-family: 'Rubik-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    }
}
.categories-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    width: 75%;
    margin-top: 16px;

    max-width: 300px;
    font-family: 'Rubik-Bold';
    &__label {
        font-family: 'Rubik-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    }
}
.title {
    font-family: 'Rubik-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    color: white;
}
.subtitle {
    font-family: "Rubik";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
}
hr {
    width: 38%;
    margin: 29px auto;
    background-color:  #505050;
    size: 1;
    

}


.card2 {
    width: 100%;
    margin: 0 auto;
    border-radius: 22px;
    max-height: 52vh;
    overflow: scroll;
    color: white;
  }

.portrait {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, #000000 0%, #721189 64.58%, #6D0187 100%);
        display: flex;
    flex-direction: column;
    padding-top: 5.4%;
    padding-right: 3.3%;
    padding-left: 3.3%;
    &__first {
        display: flex;
        justify-content: flex-end;
    }
}


.invite-btn {
    background: white;
    color: #1f1f1f;
    display: flex;
    width: 70%;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    margin: 5rem auto;
    border-radius: 22px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    &__img {
        height: 29px;
        width: 29px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    }
}


</style>

