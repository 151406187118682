<template>
      <div class="player-playback" v-if="show_playback">
        <div class="player-playback__time">{{ progress | msToMinutes }}</div>
        <div class="player-playback__progress-bar">
            <vue-slider
                v-if="songDuration > progress"
                v-model="progress"
                v-on:callback="onProgressChange"
                :max="songDuration"
                :tooltip="'none'"
                :dot-size="15"
                :process-style="{ background: '#FFFFFF' }"
                :bg-style="{ background: '#737575' }"
            />
        </div>
        <div class=" player-playback__time">{{ songDuration | msToMinutes }}</div>
  </div>
</template>
<script>
import api from "@/api";
import { mapGetters } from "vuex";
import VueSlider from 'vue-slider-component'

export default {
    name: "PlayerPlayback",
    components: {
      VueSlider
    },
    data() {
      return {
        zero: 0,
        progress: 0,
        progressInterval: null,
        isDragStart: false,
        songDuration: 0,
        
      };
    },
    computed: {
      ...mapGetters("audioPlayer", {
        playback: "getPlayback",
        isPlaying: "isPlaying",
        current_preview: "current_preview",
        preview_position: "preview_seek",
        preview_duration: "preview_duration"
      }),
      ...mapGetters("player", {
        context: "getPlaybackContext"
      }),
      show_playback() { return this.$store.state.audioPlayer.current_song != null },
      isPlayingPreview() { return this.$store.state.audioPlayer.isPlayingPreview }
    },
    methods: {

      updateProgress() {
        clearInterval(this.progressInterval);
        console.log("this current preview", this.current_preview)
          this.progress = this.isPlayingPreview ? this.current_preview.seek() * 1000 : this.context.position
          if (this.isPlaying) {
            var interval = 250
            this.progressInterval = setInterval(() => {
              if (this.playback && this.progress + interval <= this.songDuration) {
                this.progress = this.progress + interval;
              }
            }, interval);
          }
      },


      onProgressChange(currentValue) {
        if (!this.isDragStart) {
          this.isDragStart = false;
          if (this.isPlayingPreview){
            this.current_preview.seek(currentValue)
          } else {
            api.spotify.player.seekToPosition(currentValue);  
          }
        }
      },

       

    // },
    },
    watch: {
      playback() {
        setTimeout(
          () => {if (this.isPlayingPreview){
          this.songDuration =  this.current_preview.duration() * 1000         
        } else {
          this.songDuration = this.playback?.duration_ms;
        }}
        ,500)
        
      },
      context() {
        this.progress = this.isPlayingPreview ? this.current_preview.seek() * 1000 : this.context.position
      },
      isPlayingPreview() {
        this.progress = this.isPlayingPreview ? this.current_preview.seek() * 1000 : this.context.position
      },
  
      isPlaying() {
        this.updateProgress();
      }
    },

    created() {      
        this.updateProgress();
        this.songDuration = this.isPlayingPreview ? (
          this.current_preview.duration() * 1000) : this.playback?.duration_ms;
    },
    mounted() {
      
    }



         //@MARK - THIS IS ONLY CONFIGURED FOR PREVIEWS ATM
    // song_pos: function() {
    //   if (!this.hasPreview) { return "0:00" }
  
    //   if (this.preview?.preview.playing()) {
    //     requestAnimationFrame(this.song_pos.bind(this))
    //   }
    //   var label = formatTime(Math.floor(this.preview?.preview.seek()), "seconds")
    //   if (this.$refs.left) { this.$refs.left.textContent = label }
    //   return label
}
</script>
<style lang="scss" scoped>
  .player-playback {
    display: flex;
    width: 100%;
    padding-top: 1rem;
    margin:0 auto;
  
    &__time {
      min-width: 40px;
    }

    &__progress-bar {
      width: 100%;
    }
  }
</style>