

import Vue from 'vue'
import { db  } from './firebase'
import api from '@/api';

//const pizzly = new Pizzly({ host: 'marko-pizzly.herokuapp.com', publishableKey: 'th1s1sm4rk0sk3y' }) // Initialize Pizzly
//var spotifyID = null
// initial state
const state = {//() => ({
    token: JSON.stringify(window.localStorage.getItem('token')) || '',
    query: '',
    clientId: '3758633f4f4d48b5b8421182e6ef599e',
    clientSecret: '4c39e88730e349beb045fd68f147404c',
    searchError: false,
    searchErrorMessage: "This search failed! Please try again"

}
//})

// getters
const getters = {
    spotifyAuthID: state => { return state.token },
    getQuery: state => { return state.query },
}

// actions
const actions = {
    /*
     *   Summary: This action is used to get the tracks of a playlist from the Spotify API and store them in Firebase.
     *   Parameters:
     *   @param  {[String]} playlistID [The ID of the playlist to get the tracks from]

    */
    async fetchTracksFromPlaylist(context, {playlist_id}) {
        console.log("spotify.fetchTracksFromPlaylist()", playlist_id)
        if (!context.rootGetters["auth/getAccessToken"]) { console.log("No Spotify authID found"); return; }
        var response = await api.spotify.playlists.getPlalistTracks(playlist_id)
        console.log("response", response)
        if (response) {
            var tracks = response.data.items?.map((item) => item.track)
            tracks.filter(v => v)
        } else { console.log("No tracks found") }
        return tracks
    },
    // async fetchTracksFromPlaylist(context, {playlist_id}) {
    //     console.log("spotify.fetchTracksFromPlaylist2()", playlist_id)
    //     if (!context.rootGetters["auth/getAccessToken"]) { console.log("No Spotify authID found"); return; }

    //     var response = await api.spotify.playlists.getPlalistTracks(playlist_id)
    //     console.log("response", response)
    //     if (response) {
    //         var tracks = response.data.items?.map((item) => item.track)
    //         console.log("tracks", tracks)
    //         tracks.forEach(track => {
    //             if (track) {
    //                 delete track.disc_number
    //                 delete track.explicit
    //                 delete track.episode
    //                 delete track.track
    //                 delete track.href
    //                 track.score = 1400
    //                 db.ref(`tracks/${track.id}`).update(track)
    //             }
    //         })
    //         console.log("tracks", tracks)
    //         tracks.filter(v => v)
    //         console.log("tracks", tracks)
    //     } else {
    //         console.log("No tracks found")
    //     }
    //     console.log("Spotify.fetchtracksFromPLaylist() POST PURGE", tracks)
    //     return tracks
    // },
    async fetchTracksFromArtist(context, {artist}) {
        console.log("fetchAristTracks", artist, artist.name, artist.id)
        var response = await api.spotify.recommendations.getRecommendations(50, "US", {'artists': [artist.id]})
        .catch(error => {
            console.error(`Error getting recommendations for ${artist.id}: ${error}`);
        });
        console.log("getArtistTracks got response", response)
        return response.data.tracks;
    },
    async fetchTracksFromCategory(context, {category}) {
        console.log("fetchCategoryTracks", category.name, category.id)
        var response = await api.spotify.recommendations.getRecommendations(32, "US", {'genres': [category.seed]})
        .catch(error => {
            console.error(`Error getting recommendations for ${category.id}: ${error}`);
        });
        console.log("getCategoryTracks got response", response)
        return response.data.tracks;
            
           // return this.categoryTracks[category.name.toLowerCase()]
    },

    //Tp Dp: Check for duplicates before adding
    //Add song to the TOP of the playlist so when users find their Favorites playlist, they'll see the songs they voted on most recently and are most likely to be thinking of
    async appendSongsToPlaylist(context, payload) {
        console.log("Spotify.appendSongsToPlaylist() called");
        var tracks = payload[0]
        var playlistID = payload[1] || context.rootGetters['user/profile'].favorites_playlist.id

        if (playlistID && context.getters.spotifyAuthID) {
            var uris = tracks.map(track => track.uri).toString()
            var response = await api.spotify.playlists.saveTrack(playlistID, uris)
            console.log("Save song response", response);
        } else {
            console.log("Error account.appendSongsToPlaylist() - no playlistID or auth ID included");
        }

    },



    
    async findOrCreateFavoritesPlaylist(context, payload) {
        var user_id = payload
        console.log(`spotify.createFavoritesPlaylist() called`, user_id);
        var name = "🧖🏻 My SnipitFM Songs"
        var description = "Songs I discovered on Snipit.FM"
        const snapshot = await db.ref(`user_favorites_playlists/${user_id}/`).once("value")
        var playlist_id;
        if (!snapshot.val()) { //(!context.rootGetters['user/favorites_playlist']) {
            var response = await api.spotify.playlists.createPlaylist(user_id, name, description)
            playlist_id =  response.data.id
            db.ref(`user_favorites_playlists/${user_id}/`).update({"id": playlist_id})
        } else {
            console.log(`spotify.createFavoritesPlaylist() playlist FOUND `, snapshot.val());
            playlist_id = snapshot.val().id
            //return context.rootState.user.profile.favorites_playlist
        }
       // await context.dispatch("user/bindFavoritesPlaylist", playlist_id, {root: true})
        return playlist_id


    },



    async search(context, payload) {
        console.log(`Spotify.search() called`, payload.query);
        var query = payload.query
//        Vue.set(context.rootState.modals.playlists, "query", query)
        return await api.spotify.search.search(query)
    },
    async fetchPlaylistsForGenre(context, payload) {
        console.log("fetchPlaylistsForGenre", payload.genre)
        const OFFSET = payload.offset || 0
        const MAX_RESULTS = payload.max_results || 50
        var genre_id = payload.genre
        var games = context.rootGetters["webHQ/games"]
        if (games && !games[`${genre_id}`]) {
            var response = await api.spotify.browse.getCategoryPlaylists(genre_id, OFFSET, MAX_RESULTS)
            var playlists = response.data.playlists.items
            context.commit("webHQ/ADD_PLAYLISTS_TO_GAMES", {key: genre_id, playlists: playlists}, {root: true})
            return playlists
        } else {
            return []
        }
    },
    async searchTopPlaylistsInGenre(context, payload) {
        console.log("searchTopPlaylists", payload.genre)
        const OFFSET = payload.offset || 0
        const MAX_RESULTS = payload.max_results || 50
        var genre_name = payload.genre.name
        var games = context.rootGetters["webHQ/games"]
        if (games && !games[`${genre_name}`]) {
            var q = `Top ${genre_name}`
            var response = await context.dispatch('search', {query: q})
            var playlists = response.data.playlists.items
            context.commit("webHQ/ADD_PLAYLISTS_TO_GAMES", {key: payload.genre.id, playlists: playlists}, {root: true})
            return playlists
        } else {
            return []
        }
    }

}

// mutations
const mutations = {
    setQuery(state, query) {
        state.query = query
    }

   
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

