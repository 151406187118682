import { db } from './firebase'



// initial state
const state = () => ({
   
})

// getters
const getters = {
}

// actions
const actions = {

    async createTransactionGameToPlayer(context, {player, game}) {
        console.log("createTransactionGameToPlayer()", player, game)
        var player_id = player.spotify_id
        var recipient = { id: player_id, type: "user", tokens: player.tokens  }
        var sender = { id: game.id, type: "game", tokens: game.tokens }
        var transaction = { sender: sender, recipient: recipient, amount: 0, status: 0 }

        db.ref(`games/${game.id}/allowed/${player_id}`).transaction(function(reward) {
            if (!reward) {
                transaction.error = "Player already claimed their tokens or did not vote for top 3 song "
                transaction.status = -1
                return
            } else {
                transaction.amount = reward
                return 0
            }
        }).then(async (result) => {
            if (result.committed) {
                transaction.sender.confirmed = Date()
                await db.ref(`users/${player_id}/tokens`).transaction(function (currentTokens) {
                    return currentTokens + transaction.amount;
                }).then(async (result) => {
                    console.log("RESULT2", result)
                    transaction.recipient.confirmed = result.committed ? Date() : null
                    transaction.status = result.committed ? 1 : -1
                    return
                })
            } else {
                console.log("Transaction 2 Not Confirmed. Result:", result)

            }
        }).catch(function (error) {
            console.log(error)
            transaction.status = -1
            transaction.error = error
            return
        })
        console.log("paymentGameToPlayer() - About to push new transaction", transaction)
        var senderTransactionRef = db.ref(`transactions/${transaction.sender.type}s/${transaction.sender.id}`).push()
        senderTransactionRef.set(transaction)
        var recipientTransactionRef = db.ref(`transactions/${transaction.recipient.type}s/${transaction.recipient.id}`).push()
        recipientTransactionRef.set(transaction)
        db.ref(`user_game_history/${transaction.recipient.id}/${transaction.sender.id}`).update({"allowed": 0, "receipt": `users/${player.id}/${recipientTransactionRef.key}`})
        return { key: recipientTransactionRef.key, transaction: transaction }
    

    },
    
    // async createTransactionPlayerToGame(context, {player, game }) {
    //     var player_id = player.spotify_id
    //     var expired = Date() >= game.expire_date
    //     var sender = { id: player_id, tokens: player.tokens, type: "user" }
    //     var recipient = { id: game.id, type: "game", tokens: game.tokens }
    //     console.log("payment.createTransactionPlayerToGame()", sender, recipient, game)
    //     if (sender.tokens != null && recipient.tokens != null && sender.id && recipient.id && game.fee) {
    //         if (game.players && game.players[player_id] != null) {
    //             console.log("Player approved. Already paid to play")
    //             return { status: 1 }
    //         }
    //         if (!expired) {
    //             if (player.tokens < game.fee) {
    //                 return { status: -1, 
    //                         error: "You need more tokens in order to play this game." 
    //                 }
    //             }
    //             return await context.dispatch("createTransaction", {
    //                 sender: sender,
    //                 reciever: recipient,
    //                 amount: game.fee
    //             }).then(async (result) => {
    //                 if (result.transaction.status == 1) {
    //                     return await db.ref("games").child(game.id).child("players").update({ [player_id]: `games/${result.key}` }).then(() => {
    //                         console.log("final transaction return", result.transaction)
    //                         return result.transaction
    //                     }).catch((error) => {
    //                         return { status: -1, error: "There was an error updating Firebase /players" }
    //                     })
    //                 } else {
    //                     return { status: -1, error: `Transaction Error: ${result.transaction.error}` }
    //                 }
    //             })
    //         } else {
    //             return { status: -1, error: "Game has expired" }
    //         }
    //     } else {
    //         console.log("payment.createTransactionPlayerToGame MISSING INFO", sender.id, recipient.id, sender.tokens, recipient.tokens)
    //         return {status: -1, error: "ID or tokens missing from sender or recipient."}
    //     }

    // },
    async createTransactionPlayerToGame(context, {player, game }) {
        console.log("createTransactionPlayerToGame()", player, game)
        // First check if player, game, and fee are valid
        var player_id = player.spotify_id
        var game_id = game.id || game.game_id
        const gameIsValid = !!game && !!game_id && (game.fee >= 0) && (game.tokens >= 0)
        const playerIsValid = player && player.spotify_id && (player.tokens >= 0)
        const gameParametersAreValid = (game.fee == 0) || (game.fee > 0 ? game.expire_date != null : true) // Confirm that if there is no expiration date, then there is no fee
        const playerAlreadyPaid = game.players && game.players[player_id] != null
        const gameExpired = game.expire_date ? Date() >= game.expire_date : false
        const playerNotEnoughTokens = player.tokens < game.fee
        if (playerAlreadyPaid) {
            console.log("Player approved. Already paid to play")
            return { status: 1 }
        }
        else if (gameExpired) {
            return { status: -1, error: "Game has expired" }
        }
        else if (playerNotEnoughTokens) {
            console.log("Player not enough tokens", game.players, player_id, game.players[player_id])
            return { status: -1, error: "You need more tokens in order to play this game." }
        }
        else if (!gameIsValid) { 
            console.log("payment.createTransactionPlayerToGame MISSING INFO", !!game, !!game_id, !!game.fee, !!game.tokens, gameIsValid, game)
            return {status: -1, error: "ID, tokens, or fee missing from recipient (game)."}
        }
        else if (!playerIsValid) { 
            console.log("payment.createTransactionPlayerToGame MISSING PLAYER INFO", player.spotify_id, player.tokens)
            return {status: -1, error: "ID or tokens missing from sender (player)"}
        }
        else if (!gameParametersAreValid) {
            console.log("Invalid game parameters. Game cannot be indefinite and have a fee.", game.expire_date, game.fee)
            return {status: -1, error: "Invalid game parameters. Game cannot be indefinite and have a fee."}
        } else {
            // Function set-up
            var sender = { id: player_id, tokens: player.tokens, type: "user" }
            var recipient = { id: game_id, tokens: game.tokens, type: "game" }
            console.log("payment.createTransactionPlayerToGame()", sender, recipient, game)
    
            // Function logic
            return await context.dispatch("createTransaction", {
                sender: sender,
                reciever: recipient,
                amount: game.fee
            }).then(async (result) => {
                // If transaction was successful, update the game's players list
                if (result.transaction.status == 1) {
                    return await db.ref("games").child(game_id).child("players").update({ [player_id]: `games/${result.key}` }).then(() => {
                        console.log("final transaction return", result.transaction)
                        return result.transaction
                    }).catch((error) => {
                        return { status: -1, error: `There was an error updating Firebase /players, ${error}` }
                    })
                }
                // If transaction was not successful, return the error 
                else {
                    return { status: -1, error: `Transaction Error: ${result.transaction.error}` }
                }
            })
        }
        
        

    },
    async createTransaction(context, { sender, reciever, amount }) {
        console.log("Payments.createTransaction()", sender, reciever)
        var transaction = { sender: sender, recipient: reciever, amount, status: 0 }
        var senderRef = db.ref(`${sender.type}s`).child(sender.id).child("tokens")
        var recipientRef = db.ref(`${reciever.type}s`).child(reciever.id).child("tokens")
        await senderRef.transaction(function (currentTokens) {
            var newAmount = currentTokens - amount
            if (newAmount >= 0) {
                return newAmount
            } else {
                transaction.error = `Sender does not have enough tokens. Has ${currentTokens} Requires ${amount}`
                transaction.status = -1
                return currentTokens ? currentTokens : 0
            }
        }).then(async (result) => {
            if (result.committed) {
                transaction.sender.confirmed = Date()
                await recipientRef.transaction(function (currentTokens) {
                    return currentTokens + amount;
                }).then(async (result) => {
                    transaction.recipient.confirmed = result.committed ? Date() : null
                    transaction.status = result.committed ? 1 : -1
                    return
                })
            }
        }).catch(function (error) {
            console.log(error)
            transaction.status = -1
            transaction.error = error
            return
        })
        console.log("About to push new transaction", transaction)
        var senderTransactionRef = db.ref(`transactions/${transaction.sender.type}s/${transaction.sender.id}`).push()
        senderTransactionRef.set(transaction)
        var recipientTransactionRef = db.ref(`transactions/${transaction.recipient.type}s/${transaction.recipient.id}`).push()
        recipientTransactionRef.set(transaction)
        return { key: recipientTransactionRef.key, transaction: transaction }
    }
}


const mutations = {



}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}



