<template>
    <div>
        <div>
            <p class="jackpot__date_label">Claim Your Jackpot On</p>
            <p class="jackpot__date">{{expireDate_label}}</p>
        </div>
        <hr />
        <div>
            <p class="jackpot__size_label">
                <token-icon />
                <span>Jackpot Size</span>
            </p>
            <p class="jackpot__size">{{jackpot}} TOKENS</p>
            <button @click="endGame()">End Game</button>
        </div>

    </div>
</template>

<script>
import TokenIcon from '@/components/SVGs/Token.vue'
import { gameLogic } from "@/mixins";

export default {
    name: "JackpotInfo",
    components: { TokenIcon },
    mixins: [gameLogic],
    props: ["game"],
    computed: {
        jackpot() { return this.game ? this.game.tokens : 0 },
        date_str() { return this.game.expire_date ? new Date(this.game.expire_date).toDateString() : '' },
        countdown_label() { return this.hoursMinutesAndSecondsToGameOverFormatted(this.game) },
        userTimeZone() {
            return new Intl.DateTimeFormat('en-us', { timeZoneName: 'short' })
                    .formatToParts(new Date())
                    .find(part => part.type == "timeZoneName")
                    .value
        },
        expireDate_label() {
            if (this.game?.expire_date) {
                var expire_date = new Date(this.game.expire_date)
                var dateString = expire_date.toDateString()
                var timeString = expire_date.toLocaleTimeString()
                var timezone = this.userTimeZone
                var time = `${timeString.substring(0, 5)}${timeString.substring(8)}`
                return `${dateString} at ${time} ${timezone}`
            } else { 
                return ''
            }
        },

    },
    methods: {
        endGame() {
            this.$store.dispatch("game/endGame", this.game)
        }
    }

    
}
</script>
<style lang="scss" scoped>
.jackpot {
    color: #E0E0E0;
    &__date {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        margin-bottom: 29px;
    }
    &__date_label {
        font-weight: 400;
                margin-bottom: 6px;

    }
    &__size_label {
        
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        font-size: 18px;
        line-height: 21px;
    }
    &__size {
        font-size: 24px;
        line-height: 28px;
        color: #E0E0E0;
        font-weight: 500;
        margin-top: 1rem;    
    }
}

hr {
    width: 38%;
    margin: 29px auto;
    background-color:  #505050;
    size: 1;
    

}
button {
    background: transparent;

    border-radius: 8px;
    color: white;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    //padding: 1rem 3.5rem;
    width: 76%;
    padding: 15px 0;
    border: 0;
    margin-top: 2rem;
    border: 1px solid white;
}
button:hover {
    cursor: pointer;

}
button:disabled {
    opacity: 50%;
}
</style>