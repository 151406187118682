import api from '@/api'

const state = {
    defaultDaysOpen: 1,
    // set to the last query made to the spotify api
    query: window.localStorage.getItem('query') ? window.localStorage.getItem('query') : null,
}

const getters = {
}

const mutations = {
    setQuery(state, query) {
        state.query = query
        window.localStorage.setItem('query', query)
    }
}
const actions = {
    async createGameFromCategory(context, {category}) {
        console.log("createGameFromCategory", category)
        var tracks = await context.dispatch('spotify/fetchTracksFromCategory', {category: category}, {root: true})
        console.log("createGameFromCategory tracks- ", tracks)
        return await context.dispatch("createGameFromTracks", {tracks: tracks, seed: {seed: category.seed, type: "category", query: category.name, track_limit: 8}})
    },
    async createGameFromFeaturedCategoryPlaylist(context, {category}) {
        console.log("createGameFromCategory", category)
        var tracks = await context.dispatch('spotify/fetchTracksFromCategory', {category: category}, {root: true})
        console.log("createGameFromCategory tracks- ", tracks)
        return await context.dispatch("createGameFromTracks", {tracks: tracks, seed: {seed: category.seed, type: "category", query: category.name, track_limit: 8}})
    },
    async createGameFromSearchForPlaylists(context, {query}) {
        console.log("createGameFromSearchForPlaylists", query)
        const TRACK_LIMIT = 8
        const QUERY_TYPE = "playlist"
        context.commit("setQuery", query)
        var playlists = await api.spotify.search.search(query, QUERY_TYPE)
        playlists = playlists.data.playlists.items
        // get random playlist from playlists
        var playlist = playlists.filter(playlist => playlist.tracks.total >= TRACK_LIMIT)[Math.floor(Math.random() * playlists.length)]
        console.log("createGameFromSearchForPlaylists playlist- ", playlist)
        var tracks = await context.dispatch('spotify/fetchTracksFromPlaylist', {playlist_id: playlist.id}, {root: true})
        console.log("createGameFromSearchQuery tracks- ", tracks)

//c         tracks = tracks.data.tracks.items
        return context.dispatch("createGameFromTracks", {tracks: tracks, seed: {seed: query, type: QUERY_TYPE, query: query, track_limit: TRACK_LIMIT}})
    },
    async createGameFromSearchForPlaylistsFirebase(context, {query}) {
        console.log("createGameFromSearchForPlaylistsFIREBASE", query)
        const TRACK_LIMIT = 8
        const QUERY_TYPE = "playlist"
        context.commit("setQuery", query)

        var playlist = await context.dispatch('firebase/randomPlaylist', {genre: query}, {root: true})
        console.log("createGameFromSearchForPlaylists playlist- ", playlist)
        var tracks = await context.dispatch('firebase/getRandomTracks', {playlist: playlist}, {root: true})
        console.log("createGameFromSearchQuery tracks- ", tracks)

//c         tracks = tracks.data.tracks.items
        return context.dispatch("createGameFromTracks", {tracks: tracks, seed: {seed: query, type: QUERY_TYPE, query: query, track_limit: TRACK_LIMIT}})
    },

      

    async createGameFromSearchForArtist(context, {query, query_type}) {
        console.log("createGameFromSearchQuery", query, query_type)
        if (!["artist", "track", "genre"].includes(query_type))  {
            console.log("Invalid query type", query_type)   
            return null
        }
        var artist = await api.spotify.search.search(query, query_type, 1)
        artist = artist.data.artists.items[0]
        var tracks = await context.dispatch('spotify/fetchTracksFromArtist', {artist: artist}, {root: true})
        console.log("createGameFromSearchQuery tracks- ", tracks)
        return context.dispatch("createGameFromTracks", {tracks: tracks, seed: {seed: artist.id, type: "artist", query: query, track_limit: 8}})
    },
    async createGameFromTracks(context, {tracks, game_id, seed, track_limit }) {
        var TRACKS = track_limit ? tracks.slice(0, track_limit) : tracks
        track_limit = track_limit ? track_limit : 8
        console.log("createGameFromTracks", TRACKS, seed)
        var gameID = game_id || context.rootGetters["firebase/games_ref"].push().key
        var valid_tracks = filterTracksForPreviewURL(tracks.slice(0, track_limit))
        console.log("VALID TRACKS COUNT", valid_tracks.length)
        if (valid_tracks.length < 1) { 
            console.log("No valid tracks found for tracks",  tracks)
            return null
        }
        var polls = await context.dispatch("createPollsFromTracks", {tracks: valid_tracks, poll_index: 0})
        // filter polls for values that are less than track_limit/2
        // polls = Object.keys(polls).reduce((obj, key) => {
        //     if (polls[key] < track_limit/2) {
        //         obj[key] = polls[key]
        //     }
        //     return obj
        // }, {})
        console.log("createGameFromTracks polls- ", polls)
        var game = {
            id: gameID,
            created_for: context.rootGetters["user/id"], 
            seed: seed,
            tokens: 0,
            fee: 0,
        }
        context.dispatch('addTracksToGame', { tracks: valid_tracks, game: game })
        context.dispatch("addPollsToGame", { polls, game, resetGamePolls: true })
        context.dispatch("firebase/newGame", {game: game}, {root: true})
        console.log("createGameFromTracks game- ", game)
        return game
    },

    addTracksToGame(context, { tracks, game }) {
        console.log("game.addTracksToGame()", tracks)
        var tracks_obj = Object.assign(...tracks.map(track => ({ [track.id]: { "item": track } }))) 
        game.tracks = tracks_obj
        return game
    }, 
    addPollsToGame(context, { polls, game, resetGamePolls }) {
        console.log(`game.addPollsToGame(${Object.keys(polls).length}) called`, polls, game, resetGamePolls)
        game.polls = (game.polls == null || resetGamePolls) ? polls : { ...game.polls, ...polls }
    },
    createPollsFromTracks(context, {tracks, poll_index}) {
        if (!tracks || tracks.length < 2) return {}
        var polls = {}
        for (let i = 0; i <= tracks.length-1; i += 2) {
            if (tracks[i] && tracks[i + 1]) {
                var pollID = `${tracks[i].id},${tracks[i + 1].id}`
                polls[pollID] = poll_index
                poll_index += 1
            }
        }
        return polls
    },
}

function createPollsFromTracks(tracks) {

}


function filterTracksForPreviewURL(tracks) { 
    console.log("filterTracksForPreviewURL", tracks)
    if (!tracks || tracks.length < 1) return []
    var valid_tracks = []
    for (let i = 0; i < tracks.length; i++) {        
        var track = tracks[i]
        if (track.preview_url != null) valid_tracks.push(track) 
    }
 
  
    return valid_tracks
}




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}