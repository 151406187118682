<template>
    <section>

        <div class="spinner-container" v-show="isLoading">
            <img v-if="showMeme" src="@/assets/getInLoserSnipit.png" alt=""> <br/>
            <pulse-loader />
        </div>
        
        <div v-show="!isLoading">
            <browser-header class="header" @tabSet="setTab" :active_tab="tab" :categories="tabs" />
            <!-- <div v-if="$isMobile()" class="hide-scrollbar">
                <mobile-portrait-game-browser
                    :games="currentTabGames()"
                    :tab="tab"
                    @gameSelected="gameSelected" />
               </div> -->
            <div>
                <desktop-game-browser
                    :pageSize="pageSize"
                    :currentPage="currentPage"
                    :showPrevPageBtn="showPrevPageBtn"
                    :showNextPageBtn="showNextPageBtn"
                    :games="currentTabGames()" 
                    @gameSelected="gameSelected" />
            </div> 
        </div> 
    </section>
</template>


<script>
/* eslint-disable vue/no-unused-components */

//import { db } from '@/store/modules/firebase'
import { mapGetters } from "vuex";
import DesktopGameBrowser from '@/components/GameBrowser/Desktop.vue'
//import MobileLandscapeGameBrowser from '@/components/GameBrowser/MobileLandscape.vue'
import PulseLoader from "../components/SVGs/PulseLoader.vue";
import MobilePortraitGameBrowser from '@/components/GameBrowser/MobilePortrait.vue'
import BrowserHeader from '@/components/GameBrowser/Header'
import { gameLogic } from "@/mixins";
import { db } from "../store/modules/firebase";


const PAGE_SIZE = 6
const MEME_ODDS = 1//4
export default {
    name: "GameBrowser",
    mixins: [gameLogic],
    
    components: { DesktopGameBrowser, MobilePortraitGameBrowser, BrowserHeader, PulseLoader}, //MobileLandscapeGameBrowser, 
                    
    data() {
        return {
            pageSize: PAGE_SIZE,
            currentPage: 1,
            isLoading: true,
            categories_obj: {},
        }
    },
    firebase: {
        categories_obj: db.ref('categories')
    },
      /* In order to load games we need to do the following
            1. Call webHQ/bindCommunityGames
            2. Create a computed variable for webHQ/filtered_sorted_games -> is games[community]
            2B. OR create a computed variable for webHQ/games -> is an object where each key is a genre
            3. PlaylistCard v-for game in games 
      */    
    async mounted() {
        // this.$store.dispatch('webHQ/bindCommunityGames')
        setTimeout(async () => {
            this.isLoading = false
            if (this.tabs.length > 0) { this.setTab(this.tabs[0]) }
        }, 2000)
    },
    computed: {
        tabs() { return Object.values(this.categories_obj).filter(category => !category.hidden) },
        tab: {
            get() { return this.$store.getters['webHQ/getGenreTab'] },
            set(val) { if (!this.isLoading) { this.$store.commit("webHQ/SET_GENRE_TAB", val) } }
        },
        indexStart() { return (this.currentPage - 1) * this.pageSize },
        indexEnd() { return (this.indexStart + this.pageSize)},
        showPrevPageBtn() { return this.currentPage > 1 },
        showNextPageBtn() {  return (this.indexEnd < this.currentTabGames.length - 1)  }, 
        showMeme() { return Math.floor(Math.random() * MEME_ODDS) == 1; },
        ...mapGetters({
            games: 'webHQ/games',
            filtered_community_games: 'webHQ/filtered_sorted_games',
        }),
    },
    methods: {
        currentTabGames() { return this.tab ? this.filtered_community_games(this.tab.id) : [] }, 
        gameSelected(game) {
            this.$router.push({ name: "ConfirmationPage", params: { GAME: game, id: game.id } })
        },
        setTab(tab) {
            this.tab = tab
        }

    },



    watch: {
        tab(newVal, oldVal) {
            console.log("TAB CHANGED", oldVal, newVal)
            if (newVal != oldVal && (this.games(newVal.id) == undefined) && (newVal.id != "community") ) {
                this.isLoading = true
                this.$store.dispatch('spotify/fetchPlaylistsForGenre', {genre: newVal.id}).then(() => {
                //this.$store.dispatch('spotify/searchTopPlaylistsInGenre', {genre: newVal}).then(() => {
                    this.isLoading = false
                })
            }
        },

    },
}

</script>

<style lang="scss" scoped>
section {
  color: #E8E8E8;
    background-color: #1f1f1f;
    position: relative;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 auto;
    width: 100%;
    // padding-top: 3rem;
    
}
html, body {
	position:relative;
	overflow-x:hidden;
}
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
}
@media only screen and (min-width: 480px) {
.header {
    width: 87%;
    margin: 1rem auto;
    margin-bottom: 2rem;
    padding-left: 36px;
    padding-right: 36px;
}
}
</style>