<template>
    <transition name="fade">
        <div class="modal" >
            <div class="modal__backdrop" @click="closeModal()"/>
            <div class="modal__dialog" style="display:flex">
              <div class="modal__dialog__content">
                <div v-if="showCloseIcon" class="modal__close" @click="goToRotateMobile()" >
                  <close-icon />
                </div>
                <tourney-modal v-if="showTourney" />
                <simon-cowell-modal v-else-if="showSimonCowell" />
                <rotate-mobile-modal v-else-if="showRotateMobile" @close="close" />

              </div>
                <div  class="buttons" v-if="!showRotateMobile">
                  <div @click="goToRotateMobile()" class="btn buttons__white">Skip</div>
                  <div @click="nextPage()" class="btn buttons__black">Next <right-arrow :color="'white'" /></div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
//make a vuex store for the portrait mode modal
import RotateMobileModal from './RotateMobile.vue'
import TourneyModal from './Tourney.vue'
import SimonCowellModal from './SimonCowell.vue'
import RightArrow from '../../SVGs/RightArrow.vue'
import CloseIcon from '../../SVGs/Close.vue'
export default {
    name: "PortraitModeModal",
    data() {
        return {
            index: 0
        }
    },
    computed: {
        showTourney() { return this.index == 0 },
        showSimonCowell() { return this.index == 1 },
        showRotateMobile() { return this.index == 2 },
        showCloseIcon() {  return this.index != 2 },
    },
    methods: {
        nextPage() {
            this.index += 1
        },
        close() {
            this.showRotateMobile = false
            this.showSimonCowell = true
        },

        closeModal() {
            this.$store.dispatch('modals/hide_portrait_mode_modal')
        },
        goToRotateMobile() {
            this.index = 2
        }
    },
    components: {
        RotateMobileModal,
        TourneyModal,
        SimonCowellModal,
        RightArrow,
        CloseIcon
    },
}
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  color: #fff;
  &__backdrop {
    background-color: rgba(0, 0, 0, .7);
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // z-index: 1000;
  }
  &__close {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
  }
  &__dialog {
    background-color: #1f1f1f;
    color: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 84%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    padding-bottom: env(safe-area-inset-bottom);

    &__content {
      margin-top: 5.2%;
     // height: 76.7%;
    }
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
}
.buttons {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 16vw;
    justify-content: center;
    align-items: center;
    &__white {
      border-radius: 8px;
      background: white;
      color: black;
      height: 8.7vh;
    }
    &__black {
      color: white;
      border: 1px solid #FFFFFF;

    }
}

.btn {
  border-radius: 8px;
  height: 7vh;
  width: 34.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.7vw;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal {
  display: block;
}

</style>