<template>
    <section v-if="show_mobile" :class="{bg_landscape: landscape_mode, bg: !landscape_mode}">
        <GCMobile class="mobile" :landscape_mode="landscape_mode" :game="game" :tracks="tracks" @nextGame="nextGame()" />
    </section>
    <section v-else class="bg">
        <GCDesktop  :game="game" 
                    :tracks="tracks" 
                    :ordered_tracks_all_users="ordered_tracks_for_all_users()" 
                    :ordered_tracks_current_users="ordered_tracks_for_current_user()" 
                    @nextGame="nextGame()" />
    </section>
</template>

<script>
import GCDesktop from "@/components/GameComplete/Desktop/index.vue"
import GCMobile from "@/components/GameComplete/Mobile/index.vue"
import { gameLogic, seedLogic, screenOrientationLogic } from "@/mixins";
import Vue from "vue";
import { db } from '@/store/modules/firebase'
import { mapActions, mapGetters } from "vuex";


export default {
    name: "GameComplete",
    mixins: [screenOrientationLogic, gameLogic, seedLogic],
    components: { GCMobile, GCDesktop },
    data() {
        return {
            landscape_mode: false
        };
    },
    async mounted() {
        this.setLandscapeMode()
        console.log("GameComplete.mounted()", this.landscape_mode)
        var gameSet = this.setGameAndGameResults()
        if (!gameSet) { return }
        this.distributeJackpot()
        this.watchOrientation()
        this.loadGame()
    },
    computed: {
        show_mobile() {
            return this.$isMobile()// && !this.landscape_mode;
        },
        /*
        @TODO:           This is a hack to get the tracks to show up without excessive DB requests. 

        Description:    This function creates an array of tracks from the game object 
                        and adds the voting and metadata

        Steps:          1. Create empty array
                        2. Iterate on each track in game, key is track ID
                        3. for every track in game, add votes and track data
                        4. return array
        */
        tracks() {
            if (!this.game) { return [] }
            console.log("GameComplete.tracks: ", this.game  )
            var arr = []
            var game_tracks = this.game.tracks
            var game_results = this.game_results || {}
            Object.keys(game_tracks).forEach((key) => {
                var track = game_tracks[key].item || game_tracks[key]
                var songID = track.id
                console.log("Tracks arr2:", songID, track, key)
                var voters = game_results[songID] 
                console.log("Tracks arr1:", voters, track) //songID, game_results, game_results[songID])
                if (track.item) {
                    arr.push({...voters, ...track})
                } else {
                    arr.push({...voters, ...{item: track}})
                }
            })
            console.log("Tracks arr3:", arr)
            return arr
        },

        ...mapGetters({
            game: 'context/cGame',
            game_results: 'context/game_results',
            current_tab: 'webHQ/getGenreTab',
            games: 'webHQ/games2'
        }),
    },
    methods: {
        seedVotes() {

        },
        setLandscapeMode() {
            var landscape = this.getScreenOrientation(this.$isMobile())
            var landscapeMode = landscape == "landscape" ? true : false
            Vue.set(this, "landscape_mode", landscapeMode)
        },
        setGameAndGameResults() {
            console.log("GameComplete.setGameAndGameResults()", this.$route.params.id) 
            if (this.$route.params.id) {
                this.$store.commit('context/SET_GAME_ID', { id: this.$route.params.id})
                return true
            } else {
                console.log("NO PARAMS GIVEN")
                //  GO TO A 404 PAGE
                return false
            }
        },
        distributeJackpot() {
            if (this.game && this.gameIsOver(this.game) && !this.game.allowed) { 
                console.log("JACKPOT DISTRIBUTION") 
                this.$store.dispatch('game_results/distributeJackpot') }
            else {
                console.log(" NOT READY FOR JACKPOT", this.gameIsOver(this.game))
            }
        },
        watchOrientation() {
            var orientationQuery = window.matchMedia('(orientation: portrait)')
            try {
                // Chrome & Firefox
                orientationQuery.addEventListener('change', () => {
                    console.log("ORIENTATION CHANGED")
                    Vue.set(this, "landscape_mode", !event.matches)
                });
            } catch (e1) {
                try {
                // Safari
                orientationQuery.addListener(() => {
                    console.log("ORIENTATION CHANGED")
                    Vue.set(this, "landscape_mode", !event.matches)
                });
                } catch (e2) {
                    console.error(e2);
                }
            }
        },
        nextGame() {
            this.$store.dispatch("webHQ/playNextGame", {game: this.game})
        },
        ...mapActions({
            loadGame: 'context/loadGame'
        }),
        ordered_tracks() {
            console.log("ORDERED TRACKS:", this.active)
            return this.active == "personal" //(this.preview_mode || !this.game_expired) 
                    ? this.ordered_tracks_for_current_user()
                    : this.ordered_tracks_for_all_users()
        },
        ordered_tracks_for_all_users() { 
            var arr = this.tracks
            console.log("ordered-tracks-for-all-user", arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)).map(a => this.countVotesForTrack(a)))
            this.orderedly_tracks = arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)).map(a => this.countVotesForTrack(a))

            return arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)) 
        },
        ordered_tracks_for_current_user() {
            var arr = this.tracks || []
            console.log("ordered-tracks-for-current-user", arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)).map(a => this.countVotesForTrack(a, this.user_id)))
            this.orderedly_tracks = arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)).map(a => this.countVotesForTrack(a, this.user_id))
            return arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)) 
        },
        countVotesForTrack(track, user_id) {
            if (user_id) {
                return this.userVotedForTrack(track, user_id) ? Object.keys(track.voters[user_id]).length : 0
            } else {
                // add up votes for a track
                // key represents a userID. track[key] is a dictionary. it should have 1 key per vote by the userID
                // we want to aggregate the total votes by adding 1 for every subkey
                var sum = 0
                if (track.voters) {
                    for (let userID in track.voters) {
                        let votes = track.voters[userID]
                        var votes_count = Object.keys(votes).length
                        sum += votes_count
                    }
                }
                return sum
            }
        }
    },




}
</script>

<style lang="scss" scoped>
section {
    height: 112vh;
    width: 100%;
    color: white;
    overflow: hidden;
}
.bg_landscape {
    background-image: url('~@/assets/landscapeBG.png');

}
.bg {
    background: linear-gradient(0deg, #6C0087 14.89%, #161616 50%);

}
.mobile {
    padding-left: 4vw;
}
</style>