<template>
    <div class="modal" v-show="show">
      <div class="modal__backdrop" />
        <div class="modal__wrapper" @click.prevent.self="closeModal()">
          <div class="modal__frame">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="headline">
                  <img :src="user_pfp" class="headline__pfp" />
                  <h1>Welcome, {{ username }}</h1>
                </div>
                <button class="headline__button"> First time? </button>
              </div>
              <div class="modal__body">
                <search @start="start_search"/>
                <bracket-feed :showIsLoading="showIsLoading" :brackets="brackets" :games="games" :genres="genres" @start="start"/>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { db } from '@/store/modules/firebase'

import Search from '@/components/Modals/Playlists/Search.vue';
import BracketFeed from './BracketFeed.vue';
export default {
  name: "PlaylistsModal",
  components: {
    Search,
    BracketFeed
  },
  data() {
    return {
       show: true,/// this.$store.state.modals.playlists.show,
       isSearching: false,
       selected_game: null,
       track_count: 8,
       BRACKETS: null,
       GENRES: null,
       GAMES: null
    };
  },
  /* In order to load games we need to do the following
      1. Call webHQ/bindCommunityGames
      2. Create a computed variable for webHQ/filtered_sorted_games -> is games[community]
      2B. OR create a computed variable for webHQ/games -> is an object where each key is a genre
      3. PlaylistCard v-for game in games 
  */
  async mounted() {
    await this.$store.dispatch('webHQ/bindCommunityGames')
  },

  computed: {
    username() {         return this.$store.getters['user/name'] },
    user_pfp() {          return this.$store.getters['user/image'] },

    // extracts and filters game entries from an object property called "BRACKETS", it returns an array of game entries \
    brackets() {          return this.BRACKETS ? Object.values(this.BRACKETS).flatMap(bracket => Object.entries(bracket.game_ids)) : []}, //.filter(entry => entry[1].start_date <= Date.now()) : {}},// && game.end_date >= Date.now()) : {}},
    games() { return this.GAMES ? Object.values(this.GAMES).filter(game => game.tags)
      .reduce(function(acc, curr) {
        var tag =  Object.keys(curr.tags)[0]
        if (!acc[tag]) { acc[tag] = [] }
        acc[tag].push(curr);
        return acc;
      }, {}) 
    : {}},
    showIsLoading() {     return this.brackets.length == 0 || this.isSearching },
    genres() {            return this.GENRES ? Object.values(this.GENRES).filter(genre => !genre.hidden) : [] },
    ...mapGetters({
      playlists: 'webHQ/filtered_sorted_games',
      //games: 'webHQ/games',
      user_logged_in: 'auth/user_logged_in'

    })
  },
  firebase: {
    BRACKETS: db.ref('brackets'),
    GENRES: db.ref('categories'), 
    GAMES: db.ref('games')

  },
 
  methods: {
    async joinGame(game) {
      const game_id = game.game_id || game.id
      console.log("ModalPlaylists.joinGame()", game, this.$store.getters["user/id"])
      console.log("ModalPlaylists.joinGame()", game_id, this.$store.getters["user/id"])
      if (!this.user_logged_in) {
        console.log("User not logged in")
        this.$store.dispatch("auth/login", {game_redirect_id: game_id});
      }
      else if (game_id.charAt(0) == "-") {
        console.log("Joining game, user logged in")
        this.$store.dispatch('context/joinGame', {game: this.selected_game})
      } else {
        console.log("Quick starting game, user logged in")
        var tags = this.$store.getters['webHQ/getGenreTab'] ? [this.$store.getters['webHQ/getGenreTab'].id] : []
        await this.$store.dispatch("context/quickStart", {playlist: this.selected_game, tags: tags,  track_count: this.track_count})
      }
    },
    start(game) {
      console.log("Modal.start", game);
      this.selected_game = game
      this.joinGame(game)
      this.closeModal()
      this.$store.state.context.game_mode = 0


    },
    start_search(playlist) {
      console.log("Modal.start_search", playlist)
      this.isSearching = true
      var the = this
      setTimeout(() => {
          this.start(playlist)
          this.isSearching = false
          this.$store.state.context.game_mode = 1
      }, 450);
    },
    closeModal() {
      console.log("modal closed");
      this.$emit("closeModal")
    },
    showLoadingIcon() {
        Vue.set(this,"searchStatus", 1)
    },
    errorLoadingIcon() {
        Vue.set(this,"searchStatus", 0)
    },
    hideLoadingIcon() {
        Vue.set(this,"searchStatus", 0)
    },


  }
};
</script>


<style lang="scss" scoped>
.modal {
  font-family: Rubik;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // top: 100px;
  // width: 35%;
  // bottom: 142px;
  // left: 20px;
  z-index: 9;
  color: white;
    &__backdrop {
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    
  }
  
  &__frame {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 62.5vh; // Mock: https://www.figma.com/file/2pYrHiiOwL69AX1W4yc1AN/Team%2FSnipitFM?node-id=4%3A2593&t=oFfmuQM3Bt8eHB6Z-4
    width: 62.5%; // Mock: https://www.figma.com/file/2pYrHiiOwL69AX1W4yc1AN/Team%2FSnipitFM?node-id=4%3A2593&t=oFfmuQM3Bt8eHB6Z-4
    z-index: 2;
    background: linear-gradient(180deg, #2C2C2C 0%, #02070D 100%);
    border-radius: 8px;
    margin: 0 auto;
  //  border-radius: 5px;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
  &__dialog {
    font-family: "OpenSans-Bold", sans-serif;
    background: rgba(255, 255, 255, 0.02);
//    border: 16px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    width: 96%;
    height: 93%;
   // overflow-y: hidden;
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;
  }
  &__close {
    width: 30px;
    height: 30px;
  }

  &__header {
    // padding: 10px 20px 10px;
    display: flex;
  //  position: absolute;
    align-items: center;
    justify-content: center;
//    top: 32px;
//    transform: translateX(-50%);
    padding-top: 32px;
    width: 66%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }
  &__body {
    // padding: 10px 20px 10px;
    //overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 66%;
    height: 100%;
    text-align: left;
   // overflow-y: hidden;
  }
 
}

.headline {
  display: flex;
  align-items: center;

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 80%;
  &__pfp {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 2%;
  }
  &__button {
    background: linear-gradient(180deg, #CAFF34 0%, #9BFF7B 100%);
    color: #16171B;
    font-weight: 700;
    font-size: 16px;
    width: 35%;
    height: 7.1%; //7.1% of modal dialog
    padding: 0.5rem 0;
    border-radius: 8px;
    border: none;
  }
}
.modal {
  display: block;
}
.overlay {
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
  opacity: 0.75;
  position: absolute;
  bottom: 0;
  height: 20px;
  pointer-events: none;
  right: 0;
  left: 0;
}

</style>

